<template>
  <div id="404">
    <h1>{{ops}}</h1>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        ops: "Ooops!!!"
        
      }
    },


  }
</script>

<style>
</style>
